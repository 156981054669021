
<template>
  <b-card>
    <b-row>
      <div class="m-2">
        <b-button class="float-left" variant="primarySpore" @click="back()">
          Regresar
        </b-button>
      </div>
    </b-row>
    <div>
      <b-row>

        <b-col md="12" lg="12" style="min-height: 100%;">
          <b-card class="card_statisc-meters mb-0" style="min-height: 100%;">
            <b-row>
              <b-col md="4" class="mb-1 mt-1">
                <h4>Número medidor:</h4>
                <label class="font-medium-1" style="font-size: 20px">{{
                  response.number
                }}</label><br>
                <label class="font-medium-1" style="font-size: 11px !important">{{
                  response.meterInstalled
                }}</label>
              </b-col>
              <b-col md="5" class="mb-1 mt-1">
                <h4>Tipo de medidor:</h4>
                <label class="font-medium-1" style="font-size: 20px">{{
                  response.type === '0'
                    ? 'Medidor Monofásico Especificación GWH00-09'
                    : response.type === '1'
                    ? 'Medidor Bifásico Especificación GWH00-09'
                    : response.type === '2'
                    ? 'Medidor Trifásico Especificación GWH00-09'
                    : response.type === '3'
                    ? 'Medidor Monofásico Especificación GWH00-34'
                    : response.type === '4'
                    ? 'Medidor Bifásico Especificación GWH00-34'
                    : '-'
                }}</label>
              </b-col>
              <b-col md="3" class="my-1">
                <h4>Señal:</h4>
                <feather-icon icon="RadioIcon" size="25" />
                <span class="align-middle">
                  {{ response.signal }}
                </span>
              </b-col>
              <b-col md="4" class="my-1">
                <h4>Fecha instalación:</h4>
                <label class="font-medium-1">{{
                  response.installedAt
                }}</label>
              </b-col>
              <b-col md="5" class="my-1">
                <h4>Fecha última transmisión:</h4>
                <label class="font-medium-1" style="font-size: 20px">{{
                  response.transmitedAt
                }}</label>
              </b-col>
              <b-col md="3" class="my-1">
                <h4>Contador de reinicios:</h4>
                <label class="font-medium-1">{{
                  response.counterRestart
                }}</label>
              </b-col>
              <b-col md="4" class="mb-1 mt-1">
                <h4>Serie:</h4>
                <spore-serie :serie="response.serie"></spore-serie>
              </b-col>
              <b-col md="5" class="mb-1 mt-1">
                <h4>Serie tarjeta:</h4>
                <label class="font-medium-1" style="font-size: 20px">{{
                  response.serieCard
                }}</label>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="12">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-md-0 pb-2">
                <label for="example-datepicker">Desde</label>&nbsp;
                <b-form-datepicker id="beginDate" placeholder="" v-model="beginDate" ></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-md-0 pb-2">
                <label for="example-datepicker">Hasta</label>&nbsp;
                <b-form-datepicker id="endDate" placeholder="" v-model="endDate"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="4" class="text-right">
                <b-button variant="outline-primarySpore" @click="clear">
                  <feather-icon icon="Trash2Icon" class="mr-50"/>
                  <span>Limpiar</span>
                </b-button>
                &nbsp;
                &nbsp;
                <b-button variant="primarySpore" @click="getMetersLogs">
                  <feather-icon icon="SearchIcon" class="mr-50"/>
                  <span>Buscar</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-md-0">
                <label>Mostrar</label>
                <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getMetersLogs"/>
                <label>Registros</label>
              </b-col>
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-md-0" v-if="totalRows !== 0">
                <v-select style="width: 150px !important;" v-model="csvOptionsInitial" :options="this.csvOptionsGeneral" label="name" :reduce="option => option.value" :clearable="false" />
                &nbsp; &nbsp;
                <a @click="getMetersLogsExport">
                  <feather-icon icon="DownloadCloudIcon" size="20" />
                </a>
              </b-col>
            </b-row>
          </div>

          <b-table
              v-if="responseTable.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-emptysomething
              :items="responseTable"
              :fields="tableColumns"
            >
              <template #cell(Action)="data">
                <b-form-checkbox v-if="data.item.createdAt" v-model="data.detailsShowing" plain class="vs-checkbox-con" @change="data.toggleDetails">
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ data.detailsShowing ? 'Ocultar Lecturas' : 'Mostrar Lecturas' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="data">
                <b-card>
                  <b-row>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="CloudLightningIcon" :statistic="(data.item.currentA ? decimal(data.item.currentA, 3) + ' A' : '0 A')" statistic-title="Corriente A" color="success"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ZapIcon" :statistic="(data.item.voltageA ? decimal(data.item.voltageA, 2) + ' V' : '0 V')" statistic-title="Voltaje A" color="info"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ActivityIcon" :statistic="(data.item.frecA ? decimal(data.item.frecA, 2) + ' Hz' : '0 Hz')" statistic-title="Frecuencia A" color="warning"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="CloudLightningIcon" :statistic="(data.item.currentC ? decimal(data.item.currentC, 3) + ' A' : '0 A')" statistic-title="Corriente C" color="success"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ZapIcon" :statistic="(data.item.voltageC ? decimal(data.item.voltageC, 2) + ' V' : '0 V')" statistic-title="Voltaje C" color="info"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ActivityIcon" :statistic="(data.item.frecC ? decimal(data.item.frecC, 2) + ' Hz' : '0 Hz')" statistic-title="Frecuencia C" color="warning"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="CloudLightningIcon" :statistic="(data.item.currentB ? decimal(data.item.currentB, 3) + ' A' : '0 A')" statistic-title="Corriente B" color="success"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ZapIcon" :statistic="(data.item.voltageB ? decimal(data.item.voltageB, 2) + ' V' : '0 V')" statistic-title="Voltaje B" color="info"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="ActivityIcon" :statistic="(data.item.frecB ? decimal(data.item.frecB, 2) + ' Hz' : '0 Hz')" statistic-title="Frecuencia B" color="warning"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="BatteryChargingIcon" :statistic="(data.item.energy ? data.item.energy + ' Kw' : '0 Kw')" statistic-title="Consumo" color="primary"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <b-row>
                        <statistic-card-vertical icon="BatteryChargingIcon" :statistic="(data.item.energyR ? data.item.energyR + ' Kw' : '0 Kw')" statistic-title="Energía R." color="success"/>
                      </b-row>
                    </b-col>
                    <b-col md="2" class="d-flex justify-content-center">
                      <statistic-card-vertical
                        icon="PowerIcon"
                        :statistic="(data.item.relayState === '50' ? 'Abierto' : 'Cerrado')"
                        statistic-title="Relevador"
                        :color="data.item.relayState === '50' ? 'primary' : 'secondary'"
                      />
                    </b-col>
                  </b-row>
                  <b-button variant="outline-primarySpore" @click="data.toggleDetails">
                    Ocultar Lecturas
                  </b-button>
                </b-card>
              </template>

              <template #cell(createdAt)="data">
                <div class="text-center">
                  {{ format(data.item.createdAt) }}
                </div>
              </template>
            </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-col>
        <br><br>
      </b-row>
    </div>

  </b-card>
</template>

<script>
import { formatDate, formatPreviousDate, formatDateLanguages, decimales, empty } from '@/libs/tools/helpers'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
// import listItem from '@core/spore-components/listItem/listItem.vue'
import {
  BButton, BRow, BCol, BCard, VBTooltip, BTable, BPagination, BFormCheckbox, BFormDatepicker
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import SporeSerie from './SporeSerie.vue'

export default {
  components: {
    SporeSerie,
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    /* listItem, */
    BFormCheckbox,
    BFormDatepicker,
    StatisticCardVertical
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      response: [],
      tableColumns: [
        { thClass: 'text-center', key: 'Action', label: 'Logs', thStyle: { width: '15%' } },
        { thClass: 'text-center', key: 'createdAt', sortable: true, label: 'Fecha de Creación' }
      ],
      totalRows: 0,
      totalRowsExport: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 75, 100],
      csvOptionsInitial: 5000,
      csvOptionsLimit: 5000,
      csvOptionsGeneral: [],
      perPage: 25,
      count: 0,
      countExport: 0,
      responseTable: [],
      responseExport: [],
      file: '',
      avatar: '',
      beginDate: formatPreviousDate(new Date()),
      endDate: formatDate(new Date()),
      filters: {
        limit: 10,
        offset: 0,
        byColumn: 'createdAt',
        byOrder: 'DESC',
        timezoneOffset: '',
        beginDate: '',
        endDate: ''
      },
      filtersExp: {
        timezoneOffset: '',
        beginDate: '',
        endDate: '',
        limit: this.csvOptionsLimit,
        offset: 0
      },
      urlDownload: ''
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    cantidadEntreMil (val) {
      val = val / 1000
      return val
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    redirect (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/gabinets/${uuid}/detail`)
      }
    },
    getSignalPercent (value) {
      value = parseInt(value)
      if (value === 99) return 'No conocido o no detectado'
      else if (value > 30) return '-52 dBm o muy buena'
      else if (value > 1 && value <= 30) return '-110... - 54 dBm'
      else if (value === 1) return '-111 dBm'
      else if (value === 0) return '-115 dBm o menor'
      return value
    },
    async getDetailDevice () {
      const params = {
        url: `/meters079/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.response = data.data.data

          this.response.number = data.data.data.meterNumber.replace(/^(0)+/, '')
          this.response.type = data.data.data.typeTecnology ? data.data.data.typeTecnology : ''
          this.response.transmitedAt = !empty(data.data.data.trasmitedAt) ? this.format(data.data.data.trasmitedAt) : '-'
          this.response.installedAt = !empty(data.data.data.installedAt) ? this.format(data.data.data.installedAt) : '-'
          this.response.serie = !empty(data.data.data.serie) ? data.data.data.serie : ''
          this.response.serieCard = data.data.data?.serieCard ?? ''
          this.response.signal = !empty(data.data.data.signal) ? data.data.data.signal : 'Sin señal'

          this.getMetersLogs()
        }
      })
    },
    async getMetersLogs () {
      if (this.beginDate === '' || this.endDate === '') {
        this.$swal({
          title: '¡Atención!',
          text: 'Seleccioner el rango de fechas',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.filters.timezoneOffset = (new Date().getTimezoneOffset())
        if (this.beginDate) {
          this.filters.beginDate = this.beginDate
        }
        if (this.endDate) {
          this.filters.endDate = this.endDate
        }
        const params = {
          url: `/meters079/log/${this.uuid}`,
          method: 'GET',
          params: this.filters
        }
        request(params).then(data => {
          this.responseTable = data.data.data.rows
          this.totalRows = data.data.data.count
          this.count = data.data.data.rows.length
          this.countSelectorCSV()
        })
      }
    },
    countSelectorCSV () {
      this.csvOptionsGeneral = []
      for (let i = this.csvOptionsLimit; i <= this.totalRows + (this.csvOptionsLimit - 1); i = parseInt(i) + this.csvOptionsLimit) {
        const valueNew = ((i - this.csvOptionsLimit) + 1)
        this.csvOptionsGeneral.push({
          name: `${valueNew} - ${i}`,
          value: i
        })
      }
    },
    back () {
      this.$router.push(`/meters/GWH0079/${this.uuid}/detail`)
    },
    clear () {
      this.beginDate = ''
      this.endDate = ''
      this.filters.offset = 0
      this.filters.timezoneOffset = ''
      this.filters.beginDate = ''
      this.filters.endDate = ''
      this.currentPage = 1
      this.filtersExp.timezoneOffset = ''
      this.filtersExp.beginDate = ''
      this.filtersExp.endDate = ''
      this.filtersExp.offset = 0
    },
    async getMetersLogsExport () {
      if (this.beginDate === '' || this.endDate === '') {
        this.$swal({
          title: '¡Atención!',
          text: 'Seleccioner el rango de fechas',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.filtersExp.timezoneOffset = (new Date().getTimezoneOffset())
        if (this.beginDate) {
          this.filtersExp.beginDate = this.beginDate
        }
        if (this.endDate) {
          this.filtersExp.endDate = this.endDate
        }
        this.filtersExp.limit = this.csvOptionsLimit
        this.filtersExp.offset = (this.csvOptionsInitial - this.csvOptionsLimit)
        const params = {
          url: `/meters079/log/export/${this.uuid}`,
          method: 'GET',
          params: this.filtersExp
        }
        await request(params).then(data => {
          if (data.data.code === 200) {
            this.responseExport = data.data.data.rows
            this.totalRowsExport = data.data.data.count
            this.countExport = data.data.data.rows.length
            this.createCsv()
          }
        })
      }
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Número de Medidor')
      headers.push('Tipo de medidor')
      headers.push('Fecha transmisión')
      headers.push('Hora transmisión')
      headers.push('Energía')
      headers.push('Energía Reactiva')
      headers.push('Corriente A')
      headers.push('Voltaje A')
      headers.push('Frecuencia A')
      headers.push('Corriente C')
      headers.push('Voltaje C')
      headers.push('Frecuencia C')
      headers.push('Corriente B')
      headers.push('Voltaje B')
      headers.push('Frecuencia B')
      csv.push(headers.join(','))
      this.responseExport.map(item => {
        const row = []
        row.push(this.response.number)
        row.push((this.response.type === '0' ? 'Medidor Monofásico Especificación GWH00-09' : this.response.type === '1' ? 'Medidor Bifásico Especificación GWH00-09' : this.response.type === '2' ? 'Medidor Trifásico Especificación GWH00-09' : this.response.type === '3' ? 'Medidor Monofásico Especificación GWH00-34' : this.response.type === '4' ? 'Medidor Bifásico Especificación GWH00-34' : '-'))
        row.push((item.trasmitedAt ? this.format(item.trasmitedAt) : '-'))
        row.push((item.energy ? item.energy + ' Kw' : '0 Kw'))
        row.push((item.energyR ? item.energyR + ' Kw' : '0 Kw'))
        row.push((item.currentA ? this.cantidadEntreMil(item.currentA) + ' Amperes' : '0 Amperes'))
        row.push((item.voltageA ? this.cantidadEntreMil(item.voltageA) + ' V' : '0 V'))
        row.push((item.frecA ? decimales(item.frecA, 2) + ' Hz' : '0 Hz'))
        row.push((item.currentC ? this.cantidadEntreMil(item.currentC) + ' Amperes' : '0 Amperes'))
        row.push((item.voltageC ? this.cantidadEntreMil(item.voltageC) + ' V' : '0 V'))
        row.push((item.frecC ? decimales(item.frecC, 2) + ' Hz' : '0 Hz'))
        row.push((item.currentB ? this.cantidadEntreMil(item.currentB) + ' Amperes' : '0 Amperes'))
        row.push((item.voltageB ? this.cantidadEntreMil(item.voltageB) + ' V' : '0 V'))
        row.push((item.frecB ? decimales(item.frecB, 2) + ' Hz' : '0 Hz'))
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)

      const anchor = document.createElement('a')
      anchor.href = this.urlDownload
      anchor.target = '_blank'
      anchor.download = 'Logs_Medidor.csv'
      anchor.click()
    }
  },
  created () {
    this.getDetailDevice()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getMetersLogs()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
