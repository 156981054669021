<script>

export default {
  props: ['serie'],
  computed: {
    firstPart () {
      if (!this.serie) {
        return ''
      }

      return this.serie.split(' ').slice(0, 2).join(' ')
    },
    secondPart () {
      if (!this.serie) {
        return ''
      }

      return this.serie.split(' ').slice(2, 3)[0]
    },
    thirdPart () {
      if (!this.serie) {
        return ''
      }

      return this.serie.split(' ').slice(3)[0]
    }
  }
}
</script>

<template>
    <div class="font-medium-1" style="font-weight: 400;"> {{firstPart}} <strong style="font-weight: 800; color: black;">{{ secondPart }}</strong> {{ thirdPart }}</div>
</template>

<style scoped>

.bold {
  font-weight: 800;
  color: red;
}
</style>
